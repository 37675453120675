import Actions from "modules/actions";

import NpiActions from "store/actions/npi";
import OrderActions from "store/actions/order";
import TestOrderedActions from "store/actions/test-ordered";
import PatientSignatureActions from "store/actions/patient-signature";
import PatientActions from "store/actions/patient";
import PhysicianActions from "store/actions/physician";
import InsuranceCompanyActions from "store/actions/insuranceCompany";
import MSPActions from "store/actions/msp";
import InsuranceActions from "store/actions/insurance";
import InsuredActions from "store/actions/insured";
import AddressActions from "store/actions/address";
import TypesActions from "store/actions/types";

const npiActions = new NpiActions();
const orderActions = new OrderActions();
const testOrderedActions = new TestOrderedActions();
const patientSignatureActions = new PatientSignatureActions();
const patientActions = new PatientActions();
const physicianActions = new PhysicianActions();
const insuranceCompanyActions = new InsuranceCompanyActions();
const mspActions = new MSPActions();
const insuranceActions = new InsuranceActions();
const insuredActions = new InsuredActions();
const addressActions = new AddressActions();
const typeActions = new TypesActions();
export default class OrderEntryActions extends Actions {
  async searchNpi(npiNumber) {
    return await npiActions.searchNpi(npiNumber);
  }

  async updateOrderDetails(variables) {
    return await orderActions.update(variables);
  }

  async updateTestOrderedDetails(variables) {
    return await testOrderedActions.update(variables);
  }

  async createPatientSignature(variables) {
    return await patientSignatureActions.create(variables);
  }

  async updatePatientSignatureDetails(variables) {
    return await patientSignatureActions.update(variables);
  }

  async updatePatientDetails(variables) {
    return await patientActions.update(variables);
  }

  async updatePhysicianDetails(variables) {
    return await physicianActions.update(variables);
  }

  async getInsuranceCompanyList(variables) {
    return insuranceCompanyActions.getInsuranceCompanyList(variables);
  }

  async getMSPList(variables) {
    return mspActions.getMSPList(variables);
  }

  async createInsurance(variables) {
    return await insuranceActions.create(variables);
  }

  async updateInsurance(variables) {
    return await insuranceActions.update(variables);
  }

  async createInsured(variables) {
    return await insuredActions.create(variables);
  }

  async createAddress(variables) {
    return await addressActions.create(variables);
  }

  async getInsuranceRelationshipTypes(variables) {
    return await typeActions.getInsuranceRelationshipTypes(variables);
  }

  async sendChangeLogNotification(variables) {
    return await orderActions.sendNotification(variables);
  }
}
