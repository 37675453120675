import isEqual from "lodash/isEqual";

import AccessionOrderEntry from "./section/AccessionOrderEntry";
import DiagnosisCodes from "./section/DiagnosisCodes";
import Notes from "./section/Notes";
import PatientDemographics from "./section/PatientDemographics";
import PayorIDSelection from "./section/PayorIDSelection";
import PhysicianInformation from "./section/PhysicianInformation";
import TestOrdered from "./section/TestOrdered";

import OrderEntryActions from "modules/actions/order-entry-actions";
import ConversionTransaction from "store/actions/conversion-transaction";

export default {
  name: "OrderEntry",
  components: {
    AccessionOrderEntry,
    DiagnosisCodes,
    Notes,
    PatientDemographics,
    PayorIDSelection,
    PhysicianInformation,
    TestOrdered,
  },
  data() {
    return {
      actions: new OrderEntryActions(),
      discardModal: false,
      conversionTrxActions: new ConversionTransaction(),
      panel: [0, 1, 2, 3, 4, 5, 6],
      orderEntry: null,
      orderEntryCopy: {
        insurances: [],
        patient: {},
        physician: { orderingPhysician: {}, referringPhysician: {} },
      },
      isLoading: false,
      isSaving: false,
      changeLog: {},
    };
  },
  computed: {
    billTypesValidation() {
      // for order.billType & payorAccount.clientBillType
      const { payorAccount, insurances, billType } = this.orderEntryCopy;

      if (!insurances.length || insurances[0].payor.payorId != "C")
        return false;

      if (
        billType == "Client" &&
        payorAccount.clientBillType?.enum == "DIRECT_BILL"
      )
        return false;
      else if (
        billType != "Client" &&
        payorAccount.clientBillType?.enum != "DIRECT_BILL"
      )
        return false;

      return true;
    },
  },
  methods: {
    populateOrderEntry(order) {
      let {
        patient: { defaultPhone },
      } = order;

      if (defaultPhone.phone.length > 7) {
        order.patient.defaultPhone.phone = defaultPhone.phone.substring(0, 7);
      }
      if (!defaultPhone.areaCode) {
        order.patient.defaultPhone.areaCode = "";
      }

      this.orderEntry = JSON.parse(JSON.stringify(order));
      this.orderEntryCopy = JSON.parse(JSON.stringify(order));
    },
    sortInsurances(insurances) {
      const sortedInsurances = insurances.sort((a, b) => {
        if (a.payorPriority < b.payorPriority) return -1;
        else return 1;
      });

      const alignedPriority = sortedInsurances.map((payor, i) => {
        if (payor.payorPriority !== i + 1)
          return { ...payor, payorPriority: i + 1 };
        else return { ...payor };
      });
      return alignedPriority;
    },
    addPayor(payor) {
      const payorUsed = this.orderEntryCopy.insurances.filter(
        (item) => item.payor.id === payor.payor.id
      ).length;

      if (!payorUsed) {
        this.orderEntryCopy.insurances.push({
          ...payor,
          isDefault: "",
          id: null,
          authorizationNumber: this.orderEntryCopy.priorAuthorizationCode || "",
          groupId: "",
          groupName: "",
          insuranceGroupId: "",
          insuranceMemberId: "",
          planId: "",
          planName: "",
          subscriberId: "",
          note: {
            id: null,
            note: "",
          },
          insuranceRelationshipType: {
            id: null,
            label: "",
          },
          insured: {
            id: null,
            firstName: "",
            lastName: "",
            middleName: "",
            defaultAddress: {
              id: null,
              line1: "",
              city: "",
              stateProvince: "",
              postalCode: "",
              country: "",
            },
          },
          msp: {
            id: null,
            mspCode: "",
            mspCodeDescription: "",
          },
        });
      } else {
        this.showNotifyMessage({
          message: "Payor ID already exists.",
          type: "danger",
        });
      }
    },
    async saveOrderEntry() {
      try {
        this.isSaving = true;

        let params = {
          orderId: {
            type: "UUID!",
            value: this.orderEntryCopy.id,
            unincludeToFields: true,
          },
          queries: {},
          collectionQueries: [],
        };

        // validate section forms
        if (await this.handleUpdateOnTestOrderedSection(params)) return;
        const allSectionsAreValidated = await this.validateSectionForms();

        if (!allSectionsAreValidated) {
          this.showNotifyMessage({
            message: `Please provide all the necessary fields.`,
            type: "danger",
          });

          return;
        }

        this.handleUpdateOnAccessionOrderEntrySection(params);
        await this.handleUpdateOnPayorIdsection(params);
        await this.handleUpdateOnPatientDemoGraphicsSection(params);
        await this.handleUpdateOnPhysicianInformationSection();
        this.handleUpdateOnDiagnosisSection(params);
        this.handleUpdateOnInternalNotes(params);

        if (Object.keys(this.changeLog).length)
          await this.actions.sendChangeLogNotification({
            params: {
              orderId: {
                type: "UUID!",
                value: this.orderEntryCopy.id,
                unincludeToFields: true,
              },
              content: {
                type: "String!",
                value: JSON.stringify(this.changeLog),
                unincludeToFields: true,
              },
              permissions: {
                type: "[String]!",
                value: ["KEY_FIELDS_NOTIFICATION"],
                unincludeToFields: true,
              },
            },
          });
        if (
          Object.keys(params.queries).length > 0 ||
          params.collectionQueries.length
        ) {
          await this.actions.updateOrderDetails(params);
          if (await this.runPricingRules()) return;
          await this.setOrderEntrySaved();
        }

        this.showNotifyMessage({
          message: "Order saved.",
          type: "success",
        });

        this.$router.push("/orders");
      } catch (err) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      } finally {
        this.isSaving = false;
      }
    },
    async setOrderEntrySaved() {
      await this.actions.updateOrderDetails({
        orderId: {
          type: "UUID!",
          value: this.orderEntryCopy.id,
          unincludeToFields: true,
        },
        queries: {
          setOrderEntrySaved: {
            orderEntrySaved: {
              type: "Boolean!",
              value: true,
            },
          },
        },
      });
    },
    async runPricingRules() {
      const run = await this.actions.runPricingRules({
        orderId: {
          type: "UUID!",
          value: this.orderEntryCopy.id,
          unincludeToFields: true,
        },
        asyncProcess: {
          type: "Boolean",
          value: true,
          unincludeToFields: true,
        },
      });
      if (run.success) {
        return false;
      }
      this.showNotifyMessage({
        message: run.errors[0],
        type: "danger",
      });
      return true;
    },
    cancelSaveOrderEntry() {
      const TO_CHECK = Object.keys(this.orderEntryCopy);

      let hasChanges = false;
      for (const element of TO_CHECK) {
        const orderElement = this.orderEntry[element];
        const orderElementCopy = this.orderEntryCopy[element];

        if (element === "patient") {
          const {
            info: origInfo,
            phones: origPhones,
            defaultAddress: origDefaultAddress,
            defaultPhone: origDefaultPhone,
          } = orderElement;

          const {
            info: copyInfo,
            phones: copyPhones,
            defaultAddress: copyDefaultAddress,
            defaultPhone: copyDefaultPhone,
          } = orderElementCopy;

          if (
            origPhones.countryCode &&
            copyPhones.countryCode &&
            origPhones.phone &&
            copyPhones.phone
          ) {
            hasChanges = true;
            break;
          }

          if (
            origDefaultPhone.areaCode?.replace(/[()]/g, "") !==
              copyDefaultPhone.areaCode?.replace(/[()]/g, "") ||
            origDefaultPhone.phone.replace(/-/g, "") !==
              copyDefaultPhone.phone.replace(/-/g, "")
          ) {
            hasChanges = true;
            break;
          }

          if (!isEqual(origDefaultAddress, copyDefaultAddress)) {
            hasChanges = true;
            break;
          }

          if (!isEqual(origInfo, copyInfo)) {
            hasChanges = true;
            break;
          }
        } else {
          if (!isEqual(orderElement, orderElementCopy)) {
            hasChanges = true;
            break;
          }
        }
      }

      if (hasChanges) {
        this.discardModal = true;
      } else {
        this.$router.push("/orders");
      }
    },
    async validateSectionForms() {
      let validateForms = [
        await this.$refs.patientDemographicsSection.validateSectionForms(),
        await this.$refs.physicianInformationSection.$refs.physicianInformationForm.validateAsync(),
        await this.$refs.payorIdSection.validatePayorIdSelection(),
      ];

      return validateForms.filter((bool) => !bool).length === 0;
    },
    handleUpdateOnAccessionOrderEntrySection(params) {
      const { abn, billType } = this.orderEntryCopy;

      if (abn.length)
        params.queries["setAbn"] = {
          data: {
            type: "String",
            value: abn,
          },
        };

      if (billType.length)
        params.queries["updateBillType"] = {
          billType: {
            type: "BillType!",
            value: billType,
          },
        };
    },
    async handleUpdateOnTestOrderedSection() {
      const { insurances, billType, dateOfService } = this.orderEntryCopy;
      let requiredFields = [];

      // validate insurance companies for test ordered

      if (!dateOfService.length)
        requiredFields.push({
          type: "dos",
          errMsg: "Date of Service not available.",
          section: "orderEntrySec",
        });
      if (!billType)
        requiredFields.push({
          type: "billType",
          errMsg: "Bill Type is required.",
          section: "orderEntrySec",
        });
      if (!insurances.length)
        requiredFields.push({
          type: "payor",
          errMsg: "Primary Payor is required.",
          section: "payorSelection",
        });

      if (requiredFields.length) {
        this.showNotifyMessage({
          message: requiredFields[0].errMsg,
          type: "danger",
        });

        this.scrollTo(requiredFields[0].section);
        return true;
      }

      const result = await this.conversionTrxActions.commit({
        orderId: {
          value: this.$route.params.id,
          type: "UUID!",
          unincludeToFields: true,
        },
        insuranceCompanyId: {
          value: insurances[0].payor.id,
          type: "UUID!",
          unincludeToFields: true,
        },
        orderBillType: {
          value: billType,
          type: "BillType!",
          unincludeToFields: true,
        },
      });

      if (result.success) return false;
      else {
        this.showNotifyMessage({
          message: result.errors[0],
          type: "danger",
        });
        this.scrollTo("testOrderedSec");
        this.$refs.testOrderedSection.refreshData();
        return true;
      }
    },
    async handleUpdateOnPayorIdsection(params) {
      const { insurances } = this.orderEntry;
      const insurancesCopy = this.orderEntryCopy.insurances;
      const priorAuthorization = this.orderEntryCopy.priorAuthorization;

      const toRemoveInsurances = insurances.length
        ? insurances.map((item) => {
            const isExisting = insurancesCopy.find(
              (itemCopy) => itemCopy.id === item.id
            );
            return !isExisting
              ? {
                  deleteInsurance: {
                    insuranceId: {
                      type: "UUID!",
                      value: item.id,
                    },
                  },
                }
              : false;
          })
        : [];
      let toAddInsurances = [];

      // create insurance
      if (insurancesCopy.length) {
        for (var i = 0; i < insurancesCopy.length; i++) {
          const insurance = insurancesCopy[i];

          const { defaultAddress } = insurance.insured;

          if (insurance.id === null) {
            const createAddress = await this.createAddress(defaultAddress);
            const createInsured = await this.createInsured(
              insurance.insured,
              createAddress || null
            );
            const createInsurance = await this.mutateInsurance(
              insurance,
              createInsured,
              true
            );

            toAddInsurances.push({
              addInsurance: {
                insuranceId: {
                  type: "UUID!",
                  value: createInsurance.id,
                },
                isDefault: {
                  type: "Boolean",
                  value: i === 0,
                },
              },
            });
          } else {
            const { insured } = insurance;
            if (!insured.id || !defaultAddress.id) {
              let createAddress;
              let createInsured;
              let insuredParams = {
                insured: {},
              };

              if (
                !defaultAddress.id &&
                defaultAddress.country &&
                defaultAddress.country.length
              )
                createAddress = await this.createAddress(defaultAddress);

              if (
                !insured.id &&
                insured.firstName.length &&
                insured.firstName.length
              ) {
                createInsured = await this.createInsured(
                  insured,
                  createAddress || null
                );
              } else {
                if (createAddress) {
                  insuredParams.insured["addAddress"] = {};
                  insuredParams.insured["setDefaultAddress"] = {};

                  insuredParams.insured["addAddress"] = {
                    addressId: {
                      type: "UUID!",
                      value: createAddress.id,
                    },
                  };
                  insuredParams.insured["setDefaultAddress"] = {
                    addressId: {
                      type: "UUID!",
                      value: createAddress.id,
                    },
                  };
                }

                if (Object.keys(insured).length)
                  insuredParams.insured["updateName"] = {};
                for (let insuredKey of Object.keys(insured)) {
                  if (
                    insuredKey.toUpperCase() !== "DEFAULTADDRESS" &&
                    insuredKey.toUpperCase() !== "ID" &&
                    insured[insuredKey] !== null &&
                    insured[insuredKey].length
                  ) {
                    insuredParams.insured.updateName[`${insuredKey}`] = {
                      type: "String",
                      value: insured[insuredKey],
                    };
                  }
                }
              }

              if (createInsured)
                await this.mutateInsurance(insurance, createInsured);

              let hasUpdate;
              for (let queryKeys of Object.keys(insuredParams.insured)) {
                if (Object.keys(insuredParams.insured[queryKeys]).length)
                  hasUpdate = true;
                break;
              }
              if (hasUpdate)
                await this.mutateInsurance(insurance, insured, null, [
                  insuredParams,
                ]);
            } else {
              let insuredParams = {
                insured: {
                  updateName: {},
                  updateAddress: {},
                },
              };

              // update insured
              for (let insuredKey of Object.keys(insured)) {
                if (
                  insuredKey.toUpperCase() !== "DEFAULTADDRESS" &&
                  insuredKey.toUpperCase() !== "ID" &&
                  insured[insuredKey] !== null &&
                  insured[insuredKey].length
                ) {
                  insuredParams.insured.updateName[`${insuredKey}`] = {
                    type: "String",
                    value: insured[insuredKey],
                  };
                }
              }

              // update dafault address
              for (let addressKey of Object.keys(defaultAddress)) {
                if (
                  insured.defaultAddress[addressKey] !== null &&
                  insured.defaultAddress[addressKey].length
                ) {
                  if (addressKey.toUpperCase() === "ID") {
                    insuredParams.insured.updateAddress["addressId"] = {
                      type: "UUID!",
                      value: defaultAddress.id,
                    };
                  } else {
                    insuredParams.insured.updateAddress[`${addressKey}`] = {
                      type: "String",
                      value: defaultAddress[addressKey],
                    };
                  }
                }
              }

              await this.mutateInsurance(insurance, insured, null, [
                insuredParams,
              ]);
            }
          }
        }

        params.collectionQueries =
          params.collectionQueries.concat(toAddInsurances);
      }

      if (toRemoveInsurances.length)
        params.collectionQueries = params.collectionQueries.length
          ? params.collectionQueries.concat(toRemoveInsurances)
          : toRemoveInsurances;

      // set prior authorization based on primary payor
      if (priorAuthorization.length)
        params.queries["setPriorAuthorization"] = {
          priorAuthorization: {
            type: "Boolean",
            value: priorAuthorization.toUpperCase() === "YES" ? true : false,
          },
        };
    },
    async createAddress(defaultAddress) {
      let result;
      let addressParams = {
        params: {},
      };
      for (let defaultAddressKey of Object.keys(defaultAddress)) {
        if (
          defaultAddressKey.toUpperCase() !== "ID" &&
          defaultAddress[defaultAddressKey].length
        )
          addressParams.params[`${defaultAddressKey}`] = {
            type:
              defaultAddressKey.toUpperCase() === "LINE1"
                ? "String!"
                : "String",
            value: defaultAddress[defaultAddressKey],
          };
      }
      if (
        !defaultAddress.id &&
        defaultAddress.country.length &&
        Object.keys(addressParams.params).length
      )
        result = await this.actions.createAddress(addressParams);

      return result;
    },
    async createInsured(insured, defaultAddress = null) {
      let result;
      let insuredParams = {
        params: {},
        queries: {},
        collectionQueries: [],
      };
      if (defaultAddress) {
        insuredParams.queries["setDefaultAddress"] = {
          addressId: {
            type: "UUID!",
            value: defaultAddress.id,
          },
        };

        insuredParams.collectionQueries.push({
          addAddress: {
            addressId: {
              type: "UUID!",
              value: defaultAddress.id,
            },
          },
        });
      }
      for (let insuredKey of Object.keys(insured)) {
        if (
          insuredKey.toUpperCase() !== "ID" &&
          insuredKey.toUpperCase() !== "DEFAULTADDRESS" &&
          insured[insuredKey].length
        )
          insuredParams.params[`${insuredKey}`] = {
            type: "String",
            value: insured[insuredKey],
          };
      }
      if (!insured.id && Object.keys(insuredParams.params).length)
        result = await this.actions.createInsured(insuredParams);

      return result;
    },
    async mutateInsurance(
      insurance,
      insured,
      create = false,
      childQueries = null
    ) {
      let result;
      let insuranceQueries = {
        updatePayorPriority: {
          payorPriority: {
            type: "Int!",
            value: insurance.payorPriority,
          },
        },
        setInsuranceCompany: {
          insuranceCompanyId: {
            type: "UUID!",
            value: insurance.payor.id,
          },
        },
      };

      // set insurance relationship type
      if (insurance.insuranceRelationshipType.id)
        insuranceQueries["setInsuranceRelationshipType"] = {
          insuranceRelationshipTypeId: {
            type: "UUID!",
            value: insurance.insuranceRelationshipType.id,
          },
        };

      // set insured
      if (insured)
        insuranceQueries["setInsured"] = {
          insuredId: {
            type: "UUID!",
            value: insured.id,
          },
        };

      // set subscriber id
      if (insurance.subscriberId.length)
        insuranceQueries["updateSubscriberId"] = {
          subscriberId: {
            type: "String!",
            value: insurance.subscriberId,
          },
        };

      // set plan id
      if (insurance.planId.length)
        insuranceQueries["updatePlanId"] = {
          planId: {
            type: "String",
            value: insurance.planId,
          },
        };

      // set authorization number
      if (insurance.authorizationNumber.length)
        insuranceQueries["updateAuthorizationNumber"] = {
          authorizationNumber: {
            type: "String",
            value: insurance.authorizationNumber,
          },
        };

      // set insurance plan name
      if (insurance.planName.length)
        insuranceQueries["updateInsurancePlanName"] = {
          insurancePlanName: {
            type: "String",
            value: insurance.planName,
          },
        };

      // set group id
      if (insurance.groupId.length)
        insuranceQueries["updateGroupId"] = {
          groupId: {
            type: "String",
            value: insurance.groupId,
          },
        };

      // set insurance group name
      if (insurance.groupName.length)
        insuranceQueries["updateGroupName"] = {
          groupName: {
            type: "String",
            value: insurance.groupName,
          },
        };

      // set msp
      if (insurance.msp.id !== null)
        insuranceQueries["setMsp"] = {
          mspId: {
            type: "UUID!",
            value: insurance.msp.id,
          },
        };

      // set note
      if (insurance.note.id === null) {
        if (insurance.note.note.length)
          insuranceQueries["addNote"] = {
            note: {
              type: "String!",
              value: insurance.note.note,
            },
          };
      } else {
        insuranceQueries["updateNote"] = {
          noteId: {
            type: "UUID!",
            value: insurance.note.id,
          },
          note: {
            type: "String!",
            value: insurance.note.note,
          },
        };
      }

      if (create) {
        result = await this.actions.createInsurance({
          params: {
            insuranceGroupId: {
              type: "String",
              value: insurance.groupId,
            },
          },
          queries: insuranceQueries,
        });
      } else {
        result = await this.actions.updateInsurance({
          params: {
            insuranceId: {
              type: "UUID!",
              value: insurance.id,
              unincludeToFields: true,
            },
          },
          queries: insuranceQueries,
          childQueries,
        });
      }

      return result;
    },
    async handleUpdateOnPatientDemoGraphicsSection(params) {
      const { patient, patientSignature } = this.orderEntry;
      const patientCopy = this.orderEntryCopy.patient;
      const patientSignatureCopy = this.orderEntryCopy.patientSignature;

      // update patient signature
      if (
        patientSignatureCopy.signatureType !== patientSignature.signatureType
      ) {
        if (patientSignatureCopy.id !== null) {
          await this.actions.updatePatientSignatureDetails({
            patientSignatureId: {
              type: "UUID!",
              value: patientSignatureCopy.id,
              unincludeToFields: true,
            },
            queries: {
              updateSignatureType: {
                signatureType: {
                  type: "SignatureType!",
                  value: patientSignatureCopy.signatureType,
                },
              },
            },
          });
        } else {
          const result = await this.actions.createPatientSignature({
            patientSignatureData: {
              type: "String!",
              value: "N/A",
            },
            signatureType: {
              type: "SignatureType!",
              value: patientSignatureCopy.signatureType,
            },
          });

          if (result)
            params.queries["setPatientSignature"] = {
              patientSignatureId: {
                type: "UUID!",
                value: result.id,
              },
            };
        }

        patientSignature.signatureType = patientSignatureCopy.signatureType;
      }

      // update patient info
      let patientQueries = {};
      let patientCollectionQueries = [];

      // update name
      if (
        patient.info.lastName !== patientCopy.info.lastName ||
        patient.info.firstName !== patientCopy.info.firstName
      ) {
        let updateName = {};

        if (patient.info.lastName !== patientCopy.info.lastName)
          updateName["lastName"] = {
            type: "String",
            value: patientCopy.info.lastName,
          };

        this.changeLog["patient.lastName"] = {
          old: patient.info.lastName,
          new: patientCopy.info.lastName,
        };

        if (patient.info.firstName !== patientCopy.info.firstName)
          updateName["firstName"] = {
            type: "String",
            value: patientCopy.info.firstName,
          };

        this.changeLog["patient.firstName"] = {
          old: patient.info.firstName,
          new: patientCopy.info.firstName,
        };

        if (Object.keys(updateName).length)
          patientQueries["updateName"] = updateName;
      }

      // update dob
      if (patient.info.dateOfBirth !== patientCopy.info.dateOfBirth) {
        patientQueries["updateDateOfBirth"] = {
          dateOfBirth: {
            type: "String!",
            value: patientCopy.info.dateOfBirth,
          },
        };

        this.changeLog["patient.dateOfBirth"] = {
          old: patient.info.dateOfBirth,
          new: patientCopy.info.dateOfBirth,
        };
      }

      // update gender
      if (patient.info.gender !== patientCopy.info.gender) {
        patientQueries["updateGender"] = {
          gender: {
            type: "Gender!",
            value: patientCopy.info.gender,
          },
        };

        this.changeLog["patient.gender"] = {
          old: patient.info.gender,
          new: patientCopy.info.gender,
        };
      }

      // update address
      if (!isEqual(patient, patientCopy)) {
        if (!patientCopy.defaultAddress.id) {
          const createdAddress = await this.createAddress(
            patientCopy.defaultAddress
          );
          patientCopy.defaultAddress.id = createdAddress.id;
          patientQueries["setDefaultAddress"] = {
            addressId: {
              value: createdAddress.id,
              type: "UUID!",
            },
          };
          patientQueries["addAddress"] = {
            addressId: {
              value: createdAddress.id,
              type: "UUID!",
            },
          };
        } else {
          let updateAddress = {
            addressId: {
              type: "UUID!",
              value: patientCopy.defaultAddress.id,
            },
          };

          if (patient.defaultAddress.line1 !== patientCopy.defaultAddress.line1)
            updateAddress["line1"] = {
              type: "String",
              value: patientCopy.defaultAddress.line1,
            };

          if (patient.defaultAddress.line2 !== patientCopy.defaultAddress.line2)
            updateAddress["line2"] = {
              type: "String",
              value: patientCopy.defaultAddress.line2,
            };

          if (patient.defaultAddress.city !== patientCopy.defaultAddress.city)
            updateAddress["city"] = {
              type: "String",
              value: patientCopy.defaultAddress.city,
            };

          if (
            patient.defaultAddress.stateProvince !==
            patientCopy.defaultAddress.stateProvince
          )
            updateAddress["stateProvince"] = {
              type: "String",
              value: patientCopy.defaultAddress.stateProvince,
            };

          if (
            patient.defaultAddress.postalCode !==
            patientCopy.defaultAddress.postalCode
          )
            updateAddress["postalCode"] = {
              type: "String",
              value: patientCopy.defaultAddress.postalCode,
            };

          if (
            patient.defaultAddress.country !==
            patientCopy.defaultAddress.country
          )
            updateAddress["country"] = {
              type: "String",
              value: patientCopy.defaultAddress.country,
            };

          if (Object.keys(updateAddress).length)
            patientQueries["updateAddress"] = updateAddress;
        }
      }

      // update default phone (Home)
      if (patient.defaultPhone.phone !== patientCopy.defaultPhone.phone) {
        if (patientCopy.defaultPhone.id) {
          patientCollectionQueries.push({
            updatePhone: {
              phoneId: {
                type: "UUID!",
                value: patientCopy.defaultPhone.id,
              },
              phone: {
                type: "String",
                value: this.sanitizePhoneString(patientCopy.defaultPhone.phone),
              },
              areaCode: {
                type: "String",
                value: this.sanitizePhoneString(
                  patientCopy.defaultPhone.areaCode
                ),
              },
            },
          });
        } else if (patientCopy.defaultPhone.phone.length) {
          const createPhone = await this.createPhone(patientCopy.defaultPhone);
          patientCopy.defaultPhone.id = createPhone.id;
          patientCollectionQueries.push({
            addPhone: {
              phoneId: {
                type: "UUID!",
                value: createPhone.id,
              },
            },
            setDefaultPhone: {
              phoneId: {
                type: "UUID!",
                value: createPhone.id,
              },
            },
          });
        }
      }

      // // update phones disabled temporarily
      // if (patient.phones.phone !== patientCopy.phones.phone) {
      //   if (patientCopy.phones.id) {
      //     patientCollectionQueries.push({
      //       updatePhone: {
      //         phoneId: {
      //           type: "UUID!",
      //           value: patientCopy.phones.id,
      //         },
      //         phone: {
      //           type: "String",
      //           value: this.sanitizePhoneString(patientCopy.phones.phone),
      //         },
      //         areaCode: {
      //           type: "String",
      //           value: this.sanitizePhoneString(patientCopy.phones.areaCode),
      //         },
      //       },
      //     });
      //   } else if (patientCopy.phones.phone.length) {
      //     const createPhone = await this.createPhone(patientCopy.phones);
      //     patientCopy.phones.id = createPhone.id;
      //     patientCollectionQueries.push({
      //       addPhone: {
      //         phoneId: {
      //           type: "UUID!",
      //           value: createPhone.id,
      //         },
      //       },
      //     });
      //   }
      // }

      // update email
      if (
        patient.defaultEmail.email !== patientCopy.defaultEmail.email &&
        patientCopy.defaultEmail.id &&
        patientCopy.defaultEmail.email != ""
      ) {
        patientQueries["updateEmail"] = {
          emailId: {
            type: "UUID!",
            value: patientCopy.defaultEmail.id,
          },
          email: {
            type: "String!",
            value: patientCopy.defaultEmail.email,
          },
        };
      }

      // update patient ssn
      if (patient.info.patientSsn !== patientCopy.info.patientSsn) {
        patientQueries["updatePatientSsn"] = {
          patientSsn: {
            type: "String!",
            value: patientCopy.info.patientSsn,
          },
        };
      }

      if (Object.keys(patientQueries).length || patientCollectionQueries.length)
        await this.actions.updatePatientDetails({
          patientId: {
            type: "UUID!",
            value: patientCopy.info.id,
            unincludeToFields: true,
          },
          queries: patientQueries,
          collectionQueries: patientCollectionQueries,
        });
    },
    async handleUpdateOnPhysicianInformationSection() {
      const { orderingPhysician, referringPhysician } =
        this.orderEntry.physician;
      const orderingPhysicianCopy =
        this.orderEntryCopy.physician.orderingPhysician;
      const referringPhysicianCopy =
        this.orderEntryCopy.physician.referringPhysician;

      let orderingPhysicianQueries = {};
      let referringPhysicianQueries = {};

      // update ordering physician
      if (!isEqual(orderingPhysician, orderingPhysicianCopy)) {
        // update npi
        if (
          orderingPhysician.npi.npiNumber !==
          orderingPhysicianCopy.npi.npiNumber
        )
          orderingPhysicianQueries["updateNpi"] = {
            npiNumber: {
              type: "String",
              value: orderingPhysicianCopy.npi.npiNumber,
            },
          };

        // update name
        if (
          orderingPhysician.firstName !== orderingPhysicianCopy.firstName ||
          orderingPhysician.lastName !== orderingPhysicianCopy.lastName
        ) {
          let updateName = {};

          if (orderingPhysician.firstName !== orderingPhysicianCopy.firstName)
            updateName["firstName"] = {
              type: "String",
              value: orderingPhysicianCopy.firstName,
            };

          this.changeLog["physician.firstName"] = {
            old: orderingPhysician.firstName,
            new: orderingPhysicianCopy.firstName,
          };

          if (orderingPhysician.lastName !== orderingPhysicianCopy.lastName)
            updateName["lastName"] = {
              type: "String",
              value: orderingPhysicianCopy.lastName,
            };

          this.changeLog["physician.lastName"] = {
            old: orderingPhysician.lastName,
            new: orderingPhysicianCopy.lastName,
          };
          if (Object.keys(updateName).length)
            orderingPhysicianQueries["updateName"] = updateName;
        }

        // update taxonomy
        if (orderingPhysician.taxonomy !== orderingPhysicianCopy.taxonomy)
          orderingPhysicianQueries["updateTaxonomy"] = {
            taxonomy: {
              type: "String!",
              value: orderingPhysicianCopy.taxonomy,
            },
          };

        await this.actions.updatePhysicianDetails({
          params: {
            physicianId: {
              type: "UUID!",
              value: orderingPhysicianCopy.id,
              unincludeToFields: true,
            },
          },
          queries: orderingPhysicianQueries,
        });
      }

      // update referring physician
      if (!isEqual(referringPhysician, referringPhysicianCopy)) {
        // update npi
        if (
          referringPhysician.npi.npiNumber !==
          referringPhysicianCopy.npi.npiNumber
        )
          referringPhysicianQueries["updateNpi"] = {
            npiNumber: {
              type: "String",
              value: referringPhysicianCopy.npi.npiNumber,
            },
          };

        // update name
        if (
          referringPhysician.firstName !== referringPhysicianCopy.firstName ||
          referringPhysician.lastName !== referringPhysicianCopy.lastName
        ) {
          let updateName = {};

          if (referringPhysician.firstName !== referringPhysicianCopy.firstName)
            updateName["firstName"] = {
              type: "String",
              value: referringPhysicianCopy.firstName,
            };

          if (referringPhysician.lastName !== referringPhysicianCopy.lastName)
            updateName["lastName"] = {
              type: "String",
              value: referringPhysicianCopy.lastName,
            };

          if (Object.keys(updateName).length)
            referringPhysicianQueries["updateName"] = updateName;
        }

        // update taxonomy
        if (referringPhysician.taxonomy !== referringPhysicianCopy.taxonomy)
          referringPhysicianQueries["updateTaxonomy"] = {
            taxonomy: {
              type: "String!",
              value: referringPhysicianCopy.taxonomy,
            },
          };
        await this.actions.updateOrderDetails({
          orderId: {
            value: this.orderEntry.id,
            type: "UUID!",
            unincludeToFields: true,
          },
          childQueries: [
            { referingPhysician: { ...referringPhysicianQueries } },
          ],
        });
      }
    },
    handleUpdateOnDiagnosisSection(params) {
      const billingDiagnosticCodeCopy =
        this.orderEntryCopy.billingDiagnosticCode;
      const { diagnosticCodes } = this.orderEntryCopy;

      let toAddBillingDiagnosticCode = [];
      let index = 1;

      if (!billingDiagnosticCodeCopy.length) {
        for (let diagnosticCode of diagnosticCodes) {
          let dcParams = {
            addBillingDiagnosticCode: {
              diagnosticCodeId: {
                type: "UUID!",
                value: diagnosticCode.diagnosticCode.id,
              },
              addedBy: {
                type: "UUID!",
                value: diagnosticCode.addedBy.id,
              },
              addedDate: {
                type: "String!",
                value: this.$options.filters.getPSTDate(
                  Date.now(),
                  "MM/DD/YYYY"
                ),
              },
              active: {
                type: "Boolean",
                value: !diagnosticCode.active,
              },
              sortOrder: {
                type: "Int",
                value: index++,
              },
            },
          };

          if (diagnosticCode.notes !== null && diagnosticCode.notes.length)
            dcParams.addBillingDiagnosticCode["notes"] = {
              type: "String",
              value: diagnosticCode.notes,
            };

          toAddBillingDiagnosticCode.push(dcParams);
        }
      } else {
        for (var i = 0; i < billingDiagnosticCodeCopy.length; i++) {
          if (billingDiagnosticCodeCopy[i].id === null) {
            let bdcParams = {
              addBillingDiagnosticCode: {
                diagnosticCodeId: {
                  type: "UUID!",
                  value: billingDiagnosticCodeCopy[i].diagnosticCode.id,
                },
                addedBy: {
                  type: "UUID!",
                  value: billingDiagnosticCodeCopy[i].addedBy.id,
                },
                addedDate: {
                  type: "String!",
                  value: this.$options.filters.getPSTDate(
                    Date.now(),
                    "MM/DD/YYYY"
                  ),
                },
                active: {
                  type: "Boolean",
                  value: !billingDiagnosticCodeCopy[i].active,
                },
              },
            };

            if (
              billingDiagnosticCodeCopy[i].notes !== null &&
              billingDiagnosticCodeCopy[i].notes.length
            )
              bdcParams.addBillingDiagnosticCode["notes"] = {
                type: "String",
                value: billingDiagnosticCodeCopy[i].notes,
              };

            toAddBillingDiagnosticCode.push(bdcParams);
          }
        }
      }

      params.collectionQueries = params.collectionQueries.length
        ? params.collectionQueries.concat(...toAddBillingDiagnosticCode)
        : toAddBillingDiagnosticCode;
    },
    handleUpdateOnInternalNotes(params) {
      const { notes } = this.orderEntryCopy;
      let toAddNotes = [];

      for (var note of notes) {
        if (note.id === null) {
          toAddNotes.push({
            addNote: {
              note: {
                type: "String!",
                value: note.note,
              },
            },
          });
        }
      }

      if (toAddNotes.length)
        params.collectionQueries = params.collectionQueries.length
          ? params.collectionQueries.concat(toAddNotes)
          : toAddNotes;
    },
    async createPhone(phone) {
      const param = {
        phone: {
          type: "String!",
          value: this.sanitizePhoneString(phone.phone),
          unincludeToFields: true,
        },
        areaCode: {
          type: "String!",
          value: this.sanitizePhoneString(phone.areaCode),
          unincludeToFields: true,
        },
      };
      return await this.actions.createPhone(param);
    },
    updateDiagnosticCode(diagCode) {
      return diagCode.map((code) => {
        code.active = !code.active;
        return code;
      });
    },
    onDiscard() {
      this.$router.push("/orders");
    },
    onKeepEditing() {
      this.discardModal = false;
    },
  },
  async created() {
    try {
      this.isLoading = true;
      if (this.$route.params?.id) {
        const result = await this.actions.getOrderEntryById({
          variables: {
            property: {
              id: {
                type: "UUID!",
                value: this.$route.params.id,
              },
              ["orderBillingErrorCodes.orderByDate"]: {
                type: "DateOrderSort",
                value: {
                  dateField: "CREATED",
                  orderBy: "DESC",
                },
              },
            },
          },
        });
        this.actions.copyOrderModelToOrderEntryModel({ id: result.id });
        const orderEntry = this.actions.getProcessingOrderEntry();

        orderEntry.insurances = this.sortInsurances(orderEntry.insurances);

        orderEntry.billingDiagnosticCode = this.updateDiagnosticCode(
          orderEntry.billingDiagnosticCode
        );

        this.populateOrderEntry(orderEntry);
      } else {
        this.$router.push("/orders");
      }
    } catch (error) {
      this.showNotifyMessage({
        message: `Unable to fetch data.`,
        type: "danger",
      });
    } finally {
      this.isLoading = false;
    }
  },
};
