import Actions from "modules/actions/order-entry-actions";

const actions = new Actions();
export default {
  name: "AccessionOrderEntrySect",
  components: {},
  props: {
    sectionDisabled: {
      type: Boolean,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    payorAccount: {
      type: Object,
      required: true,
    },
    sample: {
      type: Object,
      required: true,
    },
    dateSampleTaken: {
      type: String,
      required: true,
    },
    dateFirstTransmitted: {
      type: String,
      required: true,
    },
    abn: {
      type: String,
      required: true,
    },
    requisitionId: {
      type: String,
      required: true,
    },
    billType: {
      type: String,
      required: true,
    },
    billTypeError: Boolean,
  },
  data() {
    return {
      datePickerMenus: {
        dateOfService: false,
        receiptDate: false,
        finalReportDate: false,
      },
      abnCheckbox: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
      billTypeOptions: [],
    };
  },
  computed: {
    __OrderId: {
      get() {
        return this.orderId;
      },
      set(value) {
        this.$emit("update:orderId", value);
      },
    },
    __PayorAccount: {
      get() {
        return this.payorAccount;
      },
      set(value) {
        this.$emit("update:payorAccount", value);
      },
    },
    __Sample: {
      get() {
        return this.sample;
      },
      set(value) {
        this.$emit("update:sample", value);
      },
    },
    __DateSampleTaken: {
      get() {
        return this.dateSampleTaken;
      },
      set(value) {
        this.$emit("update:dateSampleTaken", value);
      },
    },
    __DateFirstTransmitted: {
      get() {
        return this.dateFirstTransmitted;
      },
      set(value) {
        this.$emit("update:dateFirstTransmitted", value);
      },
    },
    __Abn: {
      get() {
        return this.abn;
      },
      set(value) {
        this.$emit("update:abn", value);
      },
    },
    __RequisitionId: {
      get() {
        return this.requisitionId;
      },
      set(value) {
        this.$emit("update:requisitionId", value);
      },
    },
    __BillType: {
      get() {
        return this.billType;
      },
      set(value) {
        this.$emit("update:billType", value);
      },
    },
  },
  methods: {
    formatDate(value) {
      if (!value) return "";
      return this.dateFormat(value, "MM DD YYYY");
    },
    async getBillTypes() {
      try {
        if (!this.billTypeOptions.length) {
          this.billTypeOptions = [
            {
              text: "Fetching...",
              disabled: true,
            },
          ];

          const result = await actions.getEnumValues("BillType");

          if (result)
            this.billTypeOptions = result.enumValues.map((item) => {
              return {
                text: item.name,
                value: item.name,
              };
            });
        }
      } catch (err) {
        this.showNotifyMessage({
          message: `Unable to fetch data.`,
          type: "danger",
        });
      }
    },
    selectedBillType(e) {
      this.__BillType = e;
    },
  },
  async created() {
    await this.getBillTypes()
  }
};
