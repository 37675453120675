var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',[_c('v-container',{staticClass:"white"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.sectionDisabled)?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.showAddDiagnosisModal}},[_vm._v("Add Diagnostic Code")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"diagnosis-table",attrs:{"headers":_vm.headers,"items":_vm.__DiagnosisCodes},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',{attrs:{"disabled":_vm.isCurrentBillingCode(item)}},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.diagnosticCode.code))]),_c('td',[_vm._v(_vm._s(item.diagnosticCode.description || "--"))]),_c('td',[_vm._v(" "+_vm._s(item.addedBy.id !== null ? item.addedBy.email : "systemauto")+" ")]),_c('td',[(item.diagnosticCode.created)?[_vm._v(_vm._s(_vm.getPstDate(item.diagnosticCode.created)))]:[_vm._v("--")]],2),_c('td',[_vm._v(" "+_vm._s(item.notes ? item.notes : "Data transfer from LIMS")+" ")]),_c('td',[_c('v-checkbox',{staticClass:"set-action",attrs:{"outlined":"","disabled":_vm.isCurrentBillingCode(item),"hide-details":"","color":"secondary"},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1),_c('td',[_c('div',{staticClass:"sort-actions"},[(!_vm.isFirstIndex(index) && !_vm.sectionDisabled)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeOrderPriority(item, 'asc')}}},[_c('v-icon',{staticClass:"ma-0"},[_vm._v("mdi-chevron-up")])],1):_vm._e(),(
                        !_vm.isLastIndex(index, _vm.__DiagnosisCodes) &&
                        !_vm.sectionDisabled
                      )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeOrderPriority(item, 'desc')}}},[_c('v-icon',{staticClass:"ma-0"},[_vm._v("mdi-chevron-down")])],1):_vm._e()],1)]),_c('td',[(_vm.isDeleteable(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [(_vm.isDeleteable(item))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"closeBtn"},on:{"click":function($event){return _vm.removeDiagnosisCode(item.uuid)}}},'v-icon',attrs,false),on),[_vm._v("mdi-close-circle")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()],1)])]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("Diagnosis Codes not found.")])]},proxy:true}])})],1)],1)],1)],1),(_vm.addDiagnosisModalVisibility)?_c('v-dialog',{attrs:{"persistent":"","width":"34rem"},model:{value:(_vm.addDiagnosisModalVisibility),callback:function ($$v) {_vm.addDiagnosisModalVisibility=$$v},expression:"addDiagnosisModalVisibility"}},[_c('AddDiagnosisModal',{attrs:{"dateOfService":_vm.dateOfService,"excludedOnSearch":_vm.excludedOnSearch},on:{"addDiagnosisCode":_vm.addDiagnosisCode,"closeModal":function($event){_vm.addDiagnosisModalVisibility = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }