import EnumActions from "store/actions/enum";

export default {
  name: "Section",
  components: {},
  props: {
    sectionDisabled: {
      type: Boolean,
      required: false,
    },
    patient: {
      type: Object,
      required: true,
    },
    patientSignatureType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      actions: new EnumActions(),
      signatureTypeOptions: [],
      genderOptions: [],
      rules: {
        required: val => !["", null, undefined].includes(val) || "This field is required.",
        validateEmail: val => this.validateEmail(val) || "Invalid email format.",
        date: val => !val || this.isValidDate(val) || "Invalid date.",
        nonSpecialString: val => /^[a-zA-Z0-9 ]*$/.test(val) || "Should not have special characters",
      },
      dateMenu: false,
      dateValue: "",
    };
  },
  computed: {
    __Patient: {
      get() {
        return this.patient;
      },
      set(value) {
        this.$emit("update:patient", value);
      },
    },
    __PatientSignatureType: {
      get() {
        return this.patientSignatureType;
      },
      set(value) {
        this.$emit("update:patientSignatureType", value);
      },
    },
  },
  methods: {
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    closeDateModal() {
      this.__Patient.info.dateOfBirth = this.formatDate(this.dateValue);
      this.dateMenu = false;
    },
    async validateSectionForms() {
      let validateForms = [
        await this.$refs.patientDemographicsForm.validateAsync(),
        await this.$refs.patientHomePhone.$refs.form.validateAsync(),
        this.__Patient.phones.id || this.__Patient.phones.phone.length
          ? await this.$refs.patientCellPhone.$refs.form.validateAsync()
          : true,
        await this.$refs.patientAddress.$refs.form.validateAsync(),
      ];

      return validateForms.filter((bool) => !bool).length === 0;
    },
  },
  async created() {
    try {
      const signatureTypeEnum = await this.actions.getEnumValues(
        "SignatureType"
      );
      const genderEnum = await this.actions.getEnumValues("Gender");

      if (
        signatureTypeEnum &&
        signatureTypeEnum.enumValues !== null &&
        signatureTypeEnum.enumValues.length
      )
        this.signatureTypeOptions = signatureTypeEnum.enumValues
          .filter((item) => item.name.toUpperCase() !== "UPLOAD")
          .map((item) => item.name);

      if (
        genderEnum &&
        genderEnum.enumValues !== null &&
        genderEnum.enumValues.length
      )
        this.genderOptions = genderEnum.enumValues.map((item) => item.name);
    } catch (err) {
      this.showNotifyMessage({
        message: `Unable to fetch data.`,
        type: "danger",
      });
    }
  },
};
