import OrderEntryActions from "modules/actions/order-entry-actions";

export default {
  name: "PhysicianInformationSection",
  props: {
    sectionDisabled: {
      type: Boolean,
      required: true,
    },
    orderingPhysician: {
      type: Object,
      required: true,
    },
    referringPhysician: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      actions: new OrderEntryActions(),
      isVerifyingOP: false,
      isVerifyingRP: false,
      rules: {
        required: (val) =>
          (val !== null && val.length !== 0) || "This field is required.",
        validateNpiNumber: async (val, type) => {
          const result = await this.doValidateNpiNumber(val, type);
          return result;
        },
      },
    };
  },
  computed: {
    __OrderingPhysician: {
      get() {
        return this.orderingPhysician;
      },
      set(value) {
        this.$emit("update:orderingPhysician", value);
      },
    },
    __ReferringPhysician: {
      get() {
        return this.referringPhysician;
      },
      set(value) {
        this.$emit("update:referringPhysician", value);
      },
    },
    referringPhysicianIsAvailable() {
      return this.referringPhysician.id !== null;
    },
  },
  methods: {
    async doValidateNpiNumber(value, type) {
      const verifyPhysician =
        type.toUpperCase() === "REFERRING" ? "isVerifyingRP" : "isVerifyingOP";
      try {
        this[verifyPhysician] = true;

        if (!/^\d{10}$/.test(value)) {
          this.isVerifying = false;
          return "Invalid NPI number.";
        }

        const result = await this.validateNpiNumber(value);
        if (typeof result === "string") {
          this[verifyPhysician] = false;
          return result;
        } else {
          const validateNpiUniqueNess = await this.validateNpiUniqueness(
            value,
            type
          );

          if (typeof validateNpiUniqueNess === "string") {
            return validateNpiUniqueNess;
          }
        }

        return true;
      } catch (error) {
        return "Error validating npi number.";
      } finally {
        this[verifyPhysician] = false;
      }
    },
    async validateNpiUniqueness(npiNumber, type) {
      const result = await this.actions.searchNpi(npiNumber);
      const errorMsg = "NPI number already exists.";

      if (result.length === 0) return true;

      if (type.toUpperCase() === "REFERRING") {
        if (this.referringPhysician.id !== null) {
          if (result.length > 1) return errorMsg;

          return this.referringPhysician.npi !== null &&
            result[0].id === this.referringPhysician.npi.id
            ? true
            : errorMsg;
        } else {
          return result.length ? errorMsg : true;
        }
      } else {
        if (this.orderingPhysician.id !== null) {
          if (result.length > 1) return errorMsg;

          return this.orderingPhysician.npi !== null &&
            result[0].id === this.orderingPhysician.npi.id
            ? true
            : errorMsg;
        } else {
          return result.length ? errorMsg : true;
        }
      }
    },
  },
};
