import OrderEntryActions from "modules/actions/order-entry-actions";

export default {
  name: "PayorItem",
  components: {},
  props: {
    insuranceId: { required: true },
    unkPayorLength: { type: Number, required: true, value: 0 },
    sectionDisabled: {
      type: Boolean,
      required: true,
    },
    isPrimary: { type: Boolean, required: true },
    msp: { type: Object, required: true },
    priority: { type: [String, Number], required: true },
    payor: { type: Object, required: true },
    selected: Array,
    payorName: String,
    payorGroup: String,
    planId: { type: String, required: true },
    planName: { type: String, required: true },
    subscriberId: { type: String, required: true },
    authorizationNumber: { type: String, required: true },
    priorAuthorization: { type: String, required: true },
    groupId: { type: String, required: true },
    groupName: { type: String, required: true },
    insuredRelationship: { type: Object, required: true },
    insuredLastname: { type: String, required: true },
    insuredFirstname: { type: String, required: true },
    insuredMiddlename: { required: true },
    insuredWholeAddress: {
      type: Object,
      required: true,
    },
    note: {
      type: String,
      required: true,
    },
    patient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      actions: new OrderEntryActions(),
      onDrag: false,
      getIntialData: false,
      refresher: 0,
      insuranceRelationshipTypes: {
        items: [],
        isLoading: true,
        errorOccur: false,
        totalCount: 0,
        loadInitialData: false,
      },
      payorSelect: {
        items: [],
        isLoading: true,
        errorOccur: false,
        totalCount: 0,
        loadInitialData: false,
      },
      mspSelect: {
        items: [],
        isLoading: true,
        errorOccur: false,
        totalCount: 0,
        loadInitialData: false,
      },
      rules: {
        required: [val => !["", null, undefined].includes(val) || "This field is required."],
        objRequired: (val) => {
          return val.id !== null || "This field is required.";
        },
        validateInsured: [
          (val) => {
            if (this.__Relationship.id) {
              return (
                (val !== null && val.length !== 0) || "This field is required."
              );
            }

            return true;
          },
        ],
        validateHasCountry: [
          (val) => {
            if (this.insuredWholeAddress.id) {
              return (
                (val !== null && val.length !== 0) || "This field is required."
              );
            }

            return true;
          },
        ],
        validateViaCountry: [
          (val) => {
            if (
              this.insuredWholeAddress.id ||
              (this.insuredWholeAddress.country &&
                this.insuredWholeAddress.country.length)
            ) {
              return (
                (val !== null && val.length !== 0) || "This field is required."
              );
            }

            return true;
          },
        ],
      },
      limitData: {
        insuranceCompany: [
          "insuranceCompanyId",
          "name",
          "payorGroup",
          "payorSubGroup",
        ],
        msp: ["mspCode", "mspCodeDescription"],
        insuranceRelationshipTypes: ["label"],
      },
      priorAuthorizationOptions: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
    };
  },
  computed: {
    isPayorUkn() {
      return this.payor ? this.payor.payorGroup.toUpperCase() == "UNK" : false;
    },
    isSelectedPayorIncluded() {
      return this.payor
        ? this.payorSelect.items.findIndex(
            (item) => item.value.id == this.payor.id
          ) == -1
        : false;
    },
    isAllPayorsLoaded() {
      return this.payorSelect.items.length == this.payorSelect.totalCount;
    },
    isAllIRTsLoaded() {
      return (
        this.insuranceRelationshipTypes.items.length ===
        this.insuranceRelationshipTypes.totalCount
      );
    },
    isSelectedMSPIncluded() {
      if (this.mspSelect.items.length > 1 && this.msp)
        return this.msp
          ? this.mspSelect.items.findIndex(
              (item) => item.value.id == this.msp.id
            ) == -1
          : false;
    },
    isAllMSPsLoaded() {
      return this.mspSelect.items.length == this.mspSelect.totalCount;
    },
    isRelationshipSelf() {
      return (
        this.insuredRelationship?.id !== null &&
        this.insuredRelationship?.label.toUpperCase() === "SELF"
      );
    },
    isRelationshipEmpty() {
      return this.insuredRelationship?.id === null;
    },
    payorOptions() {
      if (
        this.payorSelect.items.findIndex(
          (company) => company.id == this.payor.id
        ) == -1
      )
        return [
          ...this.payorSelect.items,
          { text: this.payor.payorId, value: this.payor },
        ];
      return this.payorSelect.items;
    },
    mspOptions() {
      if (this.mspSelect.items.findIndex((msp) => msp.id == this.msp.id) == -1)
        return [
          ...this.mspSelect.items,
          {
            text: `${this.msp.mspCode} - ${this.msp.mspCodeDescription}`,
            value: this.msp,
          },
        ];
      return this.mspSelect.items;
    },
    __Priority: {
      get() {
        return this.priority;
      },
      set(value) {
        this.$emit("update:priority", value);
      },
    },
    __Payor: {
      get() {
        return this.payor;
      },
      set(value) {
        if (this.selected.includes(value.id) && this.payor.id !== value.id) {
          this.showNotifyMessage({
            message: "Payor ID already exists.",
            type: "danger",
          });
          this.refresher += 1;
        } else this.$emit("update:payor", value);
      },
    },
    __PayorName: {
      get() {
        return this.payorName;
      },
      set(value) {
        this.$emit("update:payorName", value);
      },
    },
    __MSP: {
      get() {
        return this.msp;
      },
      set(value) {
        this.$emit("update:msp", value);
      },
    },
    __AuthorizationNumber: {
      get() {
        return this.authorizationNumber;
      },
      set(value) {
        this.$emit("update:authorizationNumber", value);
      },
    },
    __PriorAuthorization: {
      get() {
        return this.priorAuthorization;
      },
      set(value) {
        this.$emit("update:priorAuthorization", value);
      },
    },
    __GroupId: {
      get() {
        return this.groupId;
      },
      set(value) {
        this.$emit("update:groupId", value);
      },
    },
    __GroupName: {
      get() {
        return this.groupName;
      },
      set(value) {
        this.$emit("update:groupName", value);
      },
    },
    __Relationship: {
      get() {
        return this.insuredRelationship;
      },
      set(value) {
        this.$emit("update:insuredRelationship", value);
      },
    },
    __Country: {
      get() {
        return this.insuredCountry;
      },
      set(value) {
        this.$emit("update:insuredCountry", value);
      },
    },
    __Address: {
      get() {
        return this.insuredAddress;
      },
      set(value) {
        this.$emit("update:insuredAddress", value);
      },
    },
    __City: {
      get() {
        return this.insuredCity;
      },
      set(value) {
        this.$emit("update:insuredCity", value);
      },
    },
    __Firstname: {
      get() {
        return this.insuredFirstname;
      },
      set(value) {
        this.$emit("update:insuredFirstname", value);
      },
    },
    __Lastname: {
      get() {
        return this.insuredLastname;
      },
      set(value) {
        this.$emit("update:insuredLastname", value);
      },
    },
    __Middlename: {
      get() {
        return this.insuredMiddlename;
      },
      set(value) {
        this.$emit("update:insuredMiddlename", value);
      },
    },
    __State: {
      get() {
        return this.insuredState;
      },
      set(value) {
        this.$emit("update:insuredState", value);
      },
    },
    __Zipcode: {
      get() {
        return this.insuredZipcode;
      },
      set(value) {
        this.$emit("update:insuredZipcode", value);
      },
    },
    __PlanId: {
      get() {
        return this.planId;
      },
      set(value) {
        this.$emit("update:planId", value);
      },
    },
    __PlanName: {
      get() {
        return this.planName;
      },
      set(value) {
        this.$emit("update:planName", value);
      },
    },
    __SubscriberId: {
      get() {
        return this.subscriberId;
      },
      set(value) {
        this.$emit("update:subscriberId", value);
      },
    },
    __PayorGroupName: {
      get() {
        return this.payorGroup;
      },
      set(value) {
        this.$emit("update:payorGroup", value);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    __InsuredWholeAddress: {
      get() {
        return this.insuredWholeAddress;
      },
      set(value) {
        this.$emit("update:insuredWholeAddress", value);
      },
    },
    __Note: {
      get() {
        return this.note;
      },
      set(value) {
        this.$emit("update:note", value);
      },
    },
  },
  watch: {
    "payorSelect.isLazyLoading": async function () {
      if (this.isAllPayorsLoaded) return;

      await this.getPayors();
    },
    "mspSelect.isLazyLoading": async function () {
      if (this.isAllMSPsLoaded) return;

      await this.getMSPs();
    },
    "insuranceRelationshipTypes.isLazyLoading": async function () {
      if (this.isAllIRTsLoaded) return;

      await this.getInsuranceRelationshipTypes();
    },
  },
  methods: {
    parseInsuranceCompanyResponseData(companies) {
      return companies.map((company) => {
        return {
          text: company.insuranceCompanyId,
          value: {
            id: company.id,
            payorId: company.insuranceCompanyId,
            name: company.name,
            payorGroup: company.payorGroup,
            payorSubGroup: company.payorSubGroup,
          },
          disabled:
            this.unkPayorLength == 5
              ? this.payor.payorGroup != "UNK" && company.payorGroup == "UNK"
              : false,
        };
      });
    },
    parseMspResponseData(msps) {
      return msps.map((msp) => {
        return {
          text: `${msp.mspCode} - ${msp.mspCodeDescription}`,
          value: {
            id: msp.id,
            mspCode: msp.mspCode,
            mspCodeDescription: msp.mspCodeDescription,
          },
        };
      });
    },
    async getPayors() {
      try {
        if (!this.payorSelect.loadInitialData) {
          this.payorSelect.items = [
            { text: "Loading", value: {}, disabled: true },
            { text: this.payor.payorId, value: this.payor },
          ];

          const insuranceCompanies = await this.actions.getInsuranceCompanyList(
            {
              limit: 10,
              limitData: [...this.limitData.insuranceCompany, "totalCount"],
            }
          );
          this.payorSelect.loadInitialData = true;
          this.payorSelect.items =
            this.parseInsuranceCompanyResponseData(insuranceCompanies);
          this.payorSelect.totalCount = insuranceCompanies[0].totalCount;
        } else {
          {
            if (this.payorSelect.isLazyLoading) {
              const insuranceCompanies =
                await this.actions.getInsuranceCompanyList({
                  limit: 10,
                  limitData: this.limitData.insuranceCompany,
                  offset: this.payorSelect.items.length,
                });
              this.payorSelect.items = this.payorSelect.items.concat(
                this.parseInsuranceCompanyResponseData(insuranceCompanies)
              );
            }
            this.payorSelect.isLazyLoading = false;
          }
        }
        this.payorSelect.isLoading = false;
      } catch (e) {
        this.payorSelect.errorOccur = true;
      }
    },
    async getMSPs() {
      try {
        if (!this.mspSelect.loadInitialData) {
          this.mspSelect.items = [
            { text: "Loading...", value: "", disabled: true },
          ];

          if (this.msp.id)
            this.mspSelect.items.push({
              text: `${this.msp.mspCode} - ${this.msp.mspCodeDescription}`,
              value: this.msp,
            });

          const msps = await this.actions.getMSPList({
            limit: 10,
            limitData: [...this.limitData.msp, "totalCount"],
          });
          this.mspSelect.loadInitialData = true;
          this.mspSelect.items = this.parseMspResponseData(msps);
          this.mspSelect.totalCount = msps[0].totalCount;
        } else {
          {
            if (this.mspSelect.isLazyLoading) {
              const msps = await this.actions.getMSPList({
                limit: 10,
                limitData: this.limitData.msp,
                offset: this.mspSelect.items.length,
              });
              this.mspSelect.items = this.mspSelect.items.concat(
                this.parseMspResponseData(msps)
              );
            }
            this.mspSelect.isLazyLoading = false;
          }
        }
        this.mspSelect.isLoading = false;
      } catch (e) {
        this.mspSelect.errorOccur = true;
      }
    },
    async getInsuranceRelationshipTypes() {
      try {
        if (!this.insuranceRelationshipTypes.loadInitialData) {
          this.insuranceRelationshipTypes.items = [
            { label: "Loading", id: null, disabled: true },
          ];

          if (this.insuredRelationship.id)
            this.insuranceRelationshipTypes.items.push({
              label: this.insuredRelationship.label,
              value: this.insuredRelationship,
            });

          const result = await this.actions.getInsuranceRelationshipTypes({
            limit: 10,
            limitData: [
              ...this.limitData.insuranceRelationshipTypes,
              "totalCount",
            ],
          });
          this.insuranceRelationshipTypes.loadInitialData = true;
          this.insuranceRelationshipTypes.items = result.map((item) => {
            return {
              id: item.id,
              label: item.label,
            };
          });
          this.insuranceRelationshipTypes.totalCount = result.totalCount;
        } else {
          {
            if (this.insuranceRelationshipTypes.isLazyLoading) {
              const IRTs = await this.actions.getInsuranceRelationshipTypes({
                limit: 10,
                limitData: this.limitData.insuranceRelationshipTypes,
                offset: this.insuranceRelationshipTypes.items.length,
              });
              this.insuranceRelationshipTypes.items =
                this.insuranceRelationshipTypes.items.concat(
                  IRTs.map((item) => {
                    return {
                      id: item.id,
                      label: item.label,
                    };
                  })
                );
            }
            this.insuranceRelationshipTypes.isLazyLoading = false;
          }
        }
        this.insuranceRelationshipTypes.isLoading = false;
      } catch (e) {
        this.insuranceRelationshipTypes.errorOccur = true;
      }
    },
    async refetchPayors(isPayor = true) {
      if (isPayor) {
        await this.getPayorsDebounce();
        this.payorSelect.errorOccur = false;
      } else {
        await this.getMSPsDebounce();
        this.Select.errorOccur = false;
      }
    },
    selectedChange(value) {
      if (value.payorId == "UKN") {
        this.__Firstname = "";
        this.__Lastname = "";
        this.__Address = "";
        this.__City = "";
        this.__State = "";
        this.__Zipcode = "";
        this.__Country = "";
        this.__AuthorizationNumber = "";
        this.__PriorAuthorization = false;
        this.__GroupId = "";
        this.__GroupName = "";
        this.__RelationshipType = {};
        this.__MSP = {};
        this.__PlanId = "";
        this.__PlanName = "";
        this.__SubscriberId = "";
        this.$refs.form.resetValidation();
      }
    },
    setInsuranceRelationship(selected) {
      if (selected === null) {
        this.__Relationship = {
          id: null,
          label: "",
        };
      }

      if (!selected || selected.label.toUpperCase() !== "SELF") {
        this.__Firstname = "";
        this.__Lastname = "";
        this.__Middlename = "";
        this.clearAddress();
      } else {
        const { defaultAddress, info } = this.patient;
        this.__Firstname = info.firstName;
        this.__Lastname = info.lastName;
        this.__InsuredWholeAddress.country = defaultAddress.country;
        this.$nextTick(() => {
          this.__InsuredWholeAddress.line1 = defaultAddress.line1;
          this.__InsuredWholeAddress.city = defaultAddress.city;
          this.__InsuredWholeAddress.stateProvince = defaultAddress.stateProvince;
          this.__InsuredWholeAddress.postalCode = defaultAddress.postalCode;
        });
      }

      this.$emit("resetValidation");
    },
    clearAddress() {
      this.__InsuredWholeAddress.line1 = "";
      this.__InsuredWholeAddress.country = "";
      this.__InsuredWholeAddress.postalCode = "";
      this.__InsuredWholeAddress.stateProvince = "";
      this.__InsuredWholeAddress.city = "";
    },
    setPriorAuthorization(e) {
      this.__PriorAuthorization = e || "";
    },
  },
  async created() {
    // load initial data for msp
    if (!this.mspSelect.items.length && this.__MSP.id) {
      const { id, mspCode, mspCodeDescription } = this.__MSP;

      this.mspSelect.items = [
        {
          text: `${mspCode} - ${mspCodeDescription}`,
          value: { id, mspCode, mspCodeDescription },
        },
      ];
    }

    // load initial data for insurance relationship types
    if (
      !this.insuranceRelationshipTypes.items.length &&
      this.__Relationship.id
    ) {
      const { id, label } = this.__Relationship;

      this.insuranceRelationshipTypes.items = [{ id, label }];
    }

    // load initial data for payor
    if (!this.payorSelect.items.length && this.__Payor.id) {
      const { id, name, payorGroup, payorId, payorSubGroup } = this.__Payor;

      this.payorSelect.items = [
        {
          text: payorId,
          value: {
            id,
            payorId,
            name,
            payorGroup,
            payorSubGroup,
          },
        },
      ];
    }
  },
};
