import { uuid } from "vue-uuid";

import AddInternalNoteModal from "modules/orders/OrderEntry/modals/AddInternalNoteModal";

import OrderEntryActions from "modules/actions/order-entry-actions";

export default {
  name: "Section",
  components: { AddInternalNoteModal },
  props: {
    sectionDisabled: {
      type: Boolean,
      required: false,
    },
    notes: {
      type: Array,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      actions: new OrderEntryActions(),
      columns: [
        {
          text: "Internal Accession Notes",
          align: "left",
          sortable: true,
          value: "note",
        },
        {
          text: "User ID",
          align: "left",
          sortable: true,
          value: "createdBy",
        },
        {
          text: "Date/Time",
          align: "left",
          sortable: true,
          value: "created",
        },
        {
          text: "Action",
          align: "center",
        },
      ],
    };
  },
  computed: {
    __Notes: {
      get() {
        return this.notes;
      },
      set(value) {
        this.$emit("update:notes", value);
      },
    },
  },
  methods: {
    async addNote(note) {
      try {
        const user = this.actions.getUser();
        this.__Notes.push({
          uuid: uuid.v1(),
          id: null,
          note: note,
          created: "",
          createdBy: {
            id: user.id,
            username: user.email,
          },
        });

        this.dialog = false;
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });

        this.dialog = false;
      }
    },
    removeNote(note) {
      const findIndex = this.__Notes.findIndex(
        (item) => item.uuid && item.uuid === note.uuid
      );

      if (findIndex > -1) this.__Notes.splice(findIndex, 1);
    },
    isNoteNotIncludedInOrder(noteId) {
      return noteId === null;
    },
  },
};
