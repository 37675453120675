import PayorItem from "modules/orders/OrderEntry/components/PayorItem";
import OrderEntryActions from "modules/actions/order-entry-actions";
import AddPayorModal from "modules/orders/Accession/modals/AddPayorModal";

export default {
  name: "PayorSelection",
  components: { PayorItem, AddPayorModal },
  props: {
    sectionDisabled: Boolean,
    priorAuthorization: String,
    insurances: Array,
    patient: Object,
  },
  data() {
    return {
      actions: new OrderEntryActions(),
      panels: [0, 1, 2],
      payors: [],
      panelsOpen: null,
      onDrag: false,
      payorIdItems: [],
      payorModal: {
        isOpen: false,
      },
      limitData: {
        insuranceCompany: ["insuranceCompanyId", "name", "payorGroup"],
      },
    };
  },
  computed: {
    selectedPayorIds() {
      return this.insurances.map((data) => data.payor.id);
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    payorIdOptions() {
      return [
        { payorId: "UKN", name: null, payorGroup: null },
        { payorId: "UHC", name: "UHC Community 1", payorGroup: "Group 1" },
        { payorId: "UHD", name: "UHD Community 3", payorGroup: "Group 3" },
        { payorId: "UHE", name: "UHE Community 2", payorGroup: "Group 2" },
        { payorId: "UHF", name: "Community UHF", payorGroup: "" },
        { payorId: "UHG", name: "Community UHG", payorGroup: "" },
      ];
    },
    __PriorAuthorization: {
      get() {
        return this.priorAuthorization;
      },
      set(value) {
        this.$emit("update:priorAuthorization", value);
      },
    },
    unkPayorLength() {
      return this.insurances.filter((insurance) => {
        if (insurance.payor)
          if (insurance.payor.payorGroup == "UNK") return insurance;
      }).length;
    },
  },
  watch: {
    onDrag(val) {
      if (val) {
        this.panelsOpen = this.panels;
        this.panels = [];
      } else {
        this.panels = this.panelsOpen;
        this.panelsOpen = null;
      }
    },
    insurances(payors) {
      this.payors = payors;
    },
    payors(payors) {
      this.$emit("update:insurances", payors);
    },
  },
  methods: {
    doClick(e, index) {
      e.preventDefault();
      e.stopPropagation();
      this.payors.splice(index, 1);
    },
    addPayor() {
      const length = this.payors.length + 1;
      this.payors.push({
        priority: length,
        payorId: "UHX-N",
        payorName: `UHX-N Community ${length}`,
      });
      this.panels.push(length - 1);
    },
    onDrop() {
      for (let i = 0; i < this.insurances.length; i++)
        this.insurances[i].payorPriority = i + 1;

      this.onDrag = false;
    },
    onHold() {
      this.onDrag = true;
      setTimeout(() => {
        this.scrollTo("payorSelection");
      }, 200);
    },
    stopPropagation(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    async openPayorIdSelection() {
      let payors = this.payorIdItems;
      this.payorIdItems = [{ text: "Loading", disabled: true }];
      setTimeout(() => {
        this.payorIdItems = payors;
      }, 1000);
    },
    selectedChange(value, index) {
      this.payors[index].payorId = value.payorId;
      this.payors[index].payorName = value.payorName;
      if (value.payorId == "UKN") {
        const keys = Object.keys(this.payors[index]);
        for (let i = 0; i < keys.length; i++) {
          if (
            keys[i] == "priority" ||
            keys[i] == "payorId" ||
            keys[i] == "payorName" ||
            keys[i] == "selectedPayor"
          )
            continue;
          this.payors[index][keys[i]] = null;
        }
      }
    },
    async addSelected(selectedPayors) {
      let { insurances } = this;
      let currentCount = insurances.length;

      for (let i = 0; i < selectedPayors.length; i++) {
        this.panels.push(currentCount);
        this.$emit("addPayor", {
          payorPriority: ++currentCount,
          payor: selectedPayors[i],
        });
      }
    },
    resetValidation() {
      this.$refs.payorIdSelectionForm.resetValidation();
    },
    async validateAddressSectionForms() {
      const payorItems = this.$refs.payorItem;
      let validated = [];
      if (payorItems && payorItems.length) {
        for (let payorItem of payorItems) {
          await payorItem.$refs.addressForm.$refs.form.validateAsync();
        }
      }
      return validated.every((bool) => bool);
    },
    async validatePayorIdSelection() {
      if (!this.insurances.length) return true;

      return [
        await this.$refs.payorIdSelectionForm.validateAsync(),
        await this.validateAddressSectionForms(),
      ].every((bool) => bool);
    },
  },
  async mounted() {
    this.payors = this.insurances;
  },
  async created() {},
};
