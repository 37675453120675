var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-container',{staticClass:"white"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"ACCESSION ID","placeholder":"xxxxxxxxxxxxx","disabled":""},model:{value:(_vm.__Sample.sampleId),callback:function ($$v) {_vm.$set(_vm.__Sample, "sampleId", $$v)},expression:"__Sample.sampleId"}}),_c('v-text-field',{attrs:{"outlined":"","label":"CLIENT NAME","placeholder":"xxxxxxxxxxxxx","disabled":""},model:{value:(_vm.__PayorAccount.payorName),callback:function ($$v) {_vm.$set(_vm.__PayorAccount, "payorName", $$v)},expression:"__PayorAccount.payorName"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"CLIENT ID","placeholder":"xxxxxxxxxxxxx","disabled":""},model:{value:(_vm.__PayorAccount.payorCode),callback:function ($$v) {_vm.$set(_vm.__PayorAccount, "payorCode", $$v)},expression:"__PayorAccount.payorCode"}}),_c('v-text-field',{attrs:{"outlined":"","label":"REQUISITION ID","placeholder":"xxxxxxxxxxxxx","disabled":""},model:{value:(_vm.__RequisitionId),callback:function ($$v) {_vm.__RequisitionId=$$v},expression:"__RequisitionId"}})],1)],1),_c('label',[_vm._v("Service Details")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6","align-self":"center"}},[_c('v-select',{attrs:{"outlined":"","label":"ABN","items":_vm.abnCheckbox,"disabled":_vm.sectionDisabled},model:{value:(_vm.__Abn),callback:function ($$v) {_vm.__Abn=$$v},expression:"__Abn"}}),_c('v-text-field',{attrs:{"outlined":"","label":"DATE OF SERVICE","disabled":"","append-icon":"mdi-calendar"},model:{value:(_vm.__DateSampleTaken),callback:function ($$v) {_vm.__DateSampleTaken=$$v},expression:"__DateSampleTaken"}}),_c('v-text-field',{attrs:{"outlined":"","label":"FINAL REPORT DATE","value":_vm.$options.filters.getTimeDate(
                  _vm.__DateFirstTransmitted,
                  'MM/DD/YYYY'
                ).utc,"disabled":"","append-icon":"mdi-calendar"}}),_c('v-select',{attrs:{"outlined":"","label":"Bill Type","items":_vm.billTypeOptions,"disabled":_vm.sectionDisabled,"error":_vm.billTypeError},on:{"click":_vm.getBillTypes,"change":_vm.selectedBillType},model:{value:(_vm.__BillType),callback:function ($$v) {_vm.__BillType=$$v},expression:"__BillType"}})],1),_c('v-col',{attrs:{"cols":"6","align-self":"center"}},[_c('v-text-field',{attrs:{"outlined":"","label":"RECEIPT DATE","value":_vm.$options.filters.getTimeDate(
                  _vm.__Sample.receiveDate,
                  'MM/DD/YYYY'
                ).utc,"disabled":"","append-icon":"mdi-calendar"}}),_c('v-text-field',{attrs:{"outlined":"","label":"FACILITY NAME","disabled":""},model:{value:(_vm.__Sample.sampleStorageLaboratory.label),callback:function ($$v) {_vm.$set(_vm.__Sample.sampleStorageLaboratory, "label", $$v)},expression:"__Sample.sampleStorageLaboratory.label"}}),_c('v-menu',{attrs:{"nudge-left":"10","nudge-bottom":"15","close-on-content-click":false,"offset-y":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.billTypeError),expression:"billTypeError"}],staticClass:"rounded warning-icon",attrs:{"size":"35","color":"#ff4d57"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}])},[_c('div',{staticClass:"error-wrap rounded"},[_vm._v(" WARNING: The Payor you’re associating is not a Direct Bill account, Are you sure you want to Associate the Client’s Payor ID? ")]),_c('v-icon',{staticClass:"arrow-down",attrs:{"color":"#ff4d57","size":"24"}},[_vm._v(" mdi-menu-down ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }