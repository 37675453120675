import Actions from "modules/actions/";

import DiagnosticCode from "store/models/DiagnosticCode";

export default {
  name: "AddDiagnosisModal",
  props: {
    dateOfService: {
      required: true,
      type: String,
    },
    excludedOnSearch: {
      required: false,
      type: Array,
    },
  },
  data() {
    return {
      actions: new Actions(),
      diagnosticCodeModel: DiagnosticCode,
      diagnosticCode: {
        id: null,
        code: "",
        description: "",
        notes: "",
      },
      rules: {
        required: (val) =>
          (val !== null && val.length !== 0) || "This field is required.",
        dosMissing: () =>
          this.dateOfService.length > 0 || "Date of Service not available.",
      },
      additionalFilter: {},
    };
  },
  computed: {
    shouldDisableSaveBtn() {
      return (
        !this.dateOfService ||
        !this.diagnosticCode.code ||
        !this.diagnosticCode.notes
      );
    },
  },
  methods: {
    populateDiagnosticCode(e) {
      if (this.objectHasOwnProperty(e, "id")) {
        const { id, code, description } = e;

        this.diagnosticCode.id = id;
        this.diagnosticCode.code = code;
        this.diagnosticCode.description = description;
      }
    },
    clearDiagnosticCode() {
      this.diagnosticCode.id = null;
      this.diagnosticCode.code = "";
    },
    async addDiagnosisCode() {
      const validate = await this.$refs.addDiagnosisForm.validateAsync();
      if (!validate) return;

      const user = this.actions.getUser();

      this.$emit("addDiagnosisCode", {
        addDiagnosisCode: true,
        diagnosticCode: this.diagnosticCode,
        addedBy: {
          id: user.uuid,
          email: user.email,
        },
      });
    },
  },
  created() {
    if (this.dateOfService.length) {
      const timezoneOffset = this.getTimezoneOffset({
        date: this.dateOfService,
        minusSeconds: 1,
        zone: "America/Los_Angeles",
      });

      this.additionalFilter = {
        validityStartDate: {
          lt: this.$options.filters.getTimeDate(
            this.dateOfService,
            "YYYY-MM-DD HH:mm:ss",
            true,
            "America/Los_Angeles"
          ).utc,
          gt: "",
        },
        validityEndDate: {
          lt: "",
          gt: this.$options.filters.getTimeDate(
            this.$options.filters.getTimeDate(
              this.dateOfService,
              "YYYY-MM-DD",
              true,
              null
            ).utc,
            "YYYY-MM-DD HH:mm:ss",
            true,
            null,
            {
              days: 1,
              hours: timezoneOffset.hours,
              minutes: timezoneOffset.minutes,
              seconds: timezoneOffset.seconds,
            }
          ).utc,
        },
        archive: true,
        logicalOperator: "AND",
      };
    }
  },
};
