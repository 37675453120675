import { uuid } from "vue-uuid";

import AddDiagnosisModal from "modules/orders/OrderEntry/modals/AddDiagnosisModal";

export default {
  name: "DiagnosisCodesSection",
  components: {
    AddDiagnosisModal,
  },
  props: {
    sectionDisabled: {
      type: Boolean,
      required: true,
    },
    diagnosisCodes: {
      required: true,
      type: Array,
    },
    diagnosticCodes: {
      required: true,
      type: Array,
    },
    dateOfService: {
      required: true,
      type: String,
    },
  },
  computed: {
    __DiagnosisCodes: {
      get() {
        if (!this.diagnosisCodes.length) return this.diagnosticCodes;
        return this.diagnosisCodes;
      },
      set(value) {
        if (!this.diagnosisCodes.length)
          return this.$emit("update:diagnosticCodes", value);
        return this.$emit("update:diagnosisCodes", value);
      },
    },
    excludedOnSearch() {
      return this.__DiagnosisCodes.length
        ? this.__DiagnosisCodes.map((item) => item.diagnosticCode.id)
        : [];
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Order",
          value: "order",
          sortable: false,
        },
        {
          text: "Diagnosis Code",
          value: "code",
          sortable: false,
        },
        {
          text: "Diagnosis Code Description",
          value: "description",
          sortable: false,
        },
        {
          text: "User ID",
          value: "userId",
          sortable: false,
        },
        {
          text: "Date",
          value: "dateTime",
          sortable: false,
        },
        {
          text: "Notes",
          value: "notes",
          sortable: false,
        },
        {
          text: "Inactive",
          value: "actions",
          sortable: false,
        },
        {
          text: "",
          value: "sort",
        },
        {
          text: "",
          value: "delete",
        },
      ],
      addDiagnosisModalVisibility: false,
    };
  },
  methods: {
    showAddDiagnosisModal() {
      this.addDiagnosisModalVisibility = true;
    },
    addDiagnosisCode(e) {
      try {
        const { addDiagnosisCode, diagnosticCode, addedBy } = e;

        if (addDiagnosisCode) {
          const diagnosisCodeAlreadyExists =
            this.__DiagnosisCodes.filter(
              (bdc) => bdc.diagnosticCode.id === diagnosticCode.id
            ).length > 0;

          if (!diagnosisCodeAlreadyExists) {
            this.__DiagnosisCodes.push({
              id: null,
              uuid: uuid.v1(),
              diagnosticCode: {
                id: diagnosticCode.id,
                code: diagnosticCode.code,
                description: diagnosticCode.description,
              },
              addedBy: addedBy,
              addedDate: "",
              notes: diagnosticCode.notes,
              active: false,
            });
          } else {
            this.showNotifyMessage({
              message: "Diagnostic code already exists.",
              type: "danger",
            });
          }
        }

        this.addDiagnosisModalVisibility = false;
      } catch (err) {
        this.showNotifyMessage({
          message: `Problem has occurred while saving data.`,
          type: "danger",
        });
      }
    },
    removeDiagnosisCode(uuid) {
      const findIndex = this.__DiagnosisCodes.findIndex(
        (item) => item.uuid === uuid
      );

      if (findIndex > -1) this.__DiagnosisCodes.splice(findIndex, 1);
    },
    changeOrderPriority(diag, type) {
      let itemIndex;
      let newIndex;

      if (diag.uuid) {
        itemIndex = this.__DiagnosisCodes.findIndex(
          (item) => item.uuid === diag.uuid
        );
      } else {
        itemIndex = this.__DiagnosisCodes.findIndex(
          (item) => item.id === diag.id
        );
      }

      if (itemIndex !== undefined && itemIndex > -1) {
        const newValue = this.__DiagnosisCodes[itemIndex];

        newIndex = itemIndex + (type.toUpperCase() === "ASC" ? -1 : 1);

        // remove from collection
        this.__DiagnosisCodes.splice(itemIndex, 1);

        // reinsert from collection
        this.__DiagnosisCodes.splice(newIndex, 0, newValue);
      }
    },
    isLastIndex(index, hayStack) {
      return index + 1 === hayStack.length;
    },
    isFirstIndex(index) {
      return index === 0;
    },
    isCurrentBillingCode(item) {
      return item.id !== null && this.diagnosisCodes.length > 0;
    },
    isDeleteable(item) {
      return !item.id;
    },
    getPstDate(date) {
      return this.$options.filters.getPstUtcDate(date, "MM/DD/YYYY").pst;
    },
  },
};
